<template>
  <ContentWrapper
    :title="isEdit ? $t('news.update.formHeader') : $t('news.create.formHeader')"
    class="news-create-view"
  >
    <ValidationObserver
      v-slot="{ handleSubmit }"
    >
      <el-form
        ref="form"
        v-loading="loading"
        label-position="top"
        :model="formObject"
      >
        <el-row
          :gutter="20"
          type="flex"
        >
          <el-col :span="18">
            <el-row
              :gutter="20"
              type="flex"
            >
              <el-col :span="8">
                <FormItem
                  rules="required"
                  :label="$t('news.create.publishedAt')"
                >
                  <el-date-picker
                    v-model="formObject.publishedAt"
                    format="dd-MM-yyyy"
                    value-format="yyyy-MM-dd"
                    type="date"
                    placeholder=""
                  />
                </FormItem>
              </el-col>
              <el-col :span="8">
                <FormItem
                  rules="required"
                  :label="$t('news.create.title')"
                >
                  <el-input
                    v-model="formObject.title"
                  />
                </FormItem>
              </el-col>
              <el-col :span="8">
                <FormItem
                  rules="required"
                  :label="$t('news.create.image')"
                >
                  <el-upload
                    ref="imageUpload"
                    v-model="formObject.image"
                    action=""
                    :on-change="handleImageUpload"
                    :on-remove="() => formObject.image = null "
                    :before-remove="() => $confirm($t('global.confirmUploadCancel'))"
                    :auto-upload="false"
                    :on-exceed="() => $notify.warning($t('global.uploadFilesLimitExceeded'))"
                    :limit="1"
                    :multiple="false"
                    :accept="'image/jpg, image/png, image/gif'"
                  >
                    <el-button type="primary">
                      {{ $t('global.browse') }}
                    </el-button>
                  </el-upload>
                </FormItem>
              </el-col>
            </el-row>
            <el-row
              :gutter="20"
              type="flex"
            >
              <el-col :span="24">
                <div class="form__image-box">
                  <el-image
                    class="form__image"
                    :src="decodedImage"
                    fit="contain"
                  >
                    <div
                      slot="error"
                      class="image-slot"
                    >
                      <span>{{ $t(`news.create.imagePlaceholder`) }}</span>
                    </div>
                  </el-image>
                </div>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="6">
            <FormItem
              :label="$t('news.create.attachments')"
            >
              <el-upload
                ref="attachmentUpload"
                action=""
                :on-change="handleAttachmentUpload"
                :on-remove="(file, fileList) => attachmentFilesList = fileList"
                :on-preview="downloadPdf"
                :before-remove="() => $confirm($t('global.confirmUploadCancel'))"
                :auto-upload="false"
                :on-exceed="() => $notify.warning($t('global.uploadFilesLimitExceeded'))"
                :limit="5"
                :multiple="true"
                accept="application/pdf"
                :file-list="attachmentFilesList"
              >
                <el-button type="primary">
                  {{ $t('global.browse') }}
                </el-button>
              </el-upload>
            </FormItem>
          </el-col>
        </el-row>
        <el-row
          :gutter="20"
          type="flex"
        >
          <el-col :span="24">
            <FormItem
              rules="required"
              :label="$t('news.create.abstract')"
            >
              <el-input
                v-model="formObject.abstract"
                type="textarea"
                resize="none"
                maxlength="180"
                :rows="2"
              />
            </FormItem>
          </el-col>
        </el-row>
        <el-row
          :gutter="20"
          type="flex"
        >
          <el-col :span="24">
            <FormItem
              rules="required"
              :label="$t('news.create.text')"
            >
              <quill-editor
                v-model="formObject.text"
                :options="{placeholder: ''}"
              />
            </FormItem>
          </el-col>
        </el-row>
        <el-row
          v-if="isEdit"
          :gutter="20"
          type="flex"
        >
          <el-col :span="12">
            <FormItem
              margin-bottom-size="none"
              :label="$t('news.create.isPublished')"
            >
              <el-switch
                v-model="isPublished"
                class="form__switch"
              />
            </FormItem>
          </el-col>
        </el-row>
        <FormButtons>
          <router-link
            :to="{ name: 'newsAdminIndex' }"
            tag="el-button"
            type="secondary"
          >
            {{ $t('global.backToList') }}
          </router-link>
          <el-button
            v-if="editMode === 'create'"
            type="primary"
            native-type="submit"
            @click.prevent="handleSubmit(() => submit(NEWS_STATUSES.UNPUBLISHED) )"
          >
            {{ $t(`news.${editMode}.draftSubmitButton`) }}
          </el-button>
          <el-button
            type="primary"
            native-type="submit"
            @click.prevent="handleSubmit(() => submit(!isEdit ? NEWS_STATUSES.PUBLISHED : null) )"
          >
            {{ $t(`news.${editMode}.submitButton`) }}
          </el-button>
        </FormButtons>
      </el-form>
    </ValidationObserver>
  </ContentWrapper>
</template>

<script>
import fileSaver from 'file-saver';
import moment from 'moment';

import fileService from '@/lib/file';
import { newsApi, uploadsApi } from '@/api';
import { NEWS_STATUSES } from '@/config/constants';
import FormItem from '@/components/FormItem.vue';
import ContentWrapper from '@/components/ContentWrapper.vue';
import FormButtons from '@/components/FormButtons.vue';

export default {
  name: 'NewsCreateAdmin',
  components: {
    FormItem,
    ContentWrapper,
    FormButtons,
  },
  data() {
    return {
      loading: false,
      editMode: 'create',
      NEWS_STATUSES,
      attachmentFilesList: [],
      formObject: {
        publishedAt: null,
        title: '',
        image: '',
        abstract: '',
        text: '',
        status: 0,
        pdfFileIds: [],
      },
    };
  },
  computed: {
    isEdit() {
      return !!this.$route.params.newsId;
    },
    isPublished: {
      get() {
        return !!this.formObject.status;
      },
      set(value) {
        this.formObject.status = value ? 1 : 0;
      },
    },
    decodedImage() {
      if (this.formObject.image && this.formObject.image !== '') {
        return fileService.base64ImagetoDataUri(this.formObject.image);
      }
      return '';
    },
  },
  async created() {
    try {
      if (this.isEdit) {
        this.loading = true;
        this.editMode = 'update';
        const showResponse = await newsApi.show(this.$route.params.newsId);
        Object.keys(this.formObject).forEach((key) => {
          this.formObject[key] = showResponse.data[key];
        });
        this.attachmentFilesList = await Promise.all((this.formObject.pdfFileIds || [])
          .map(async (fileId, index) => {
            const url = await this.getAttachmentUrl(fileId);
            return { name: `${this.$t('global.attachment')} ${index + 1}`, url, fileId };
          }));
      }
    } catch (error) {
      this.$notify({ showClose: true, type: 'error', message: error.response.data.error.message });
    } finally {
      this.loading = false;
    }
  },
  methods: {
    async submit(status) {
      this.loading = true;
      const pdfFileIds = await this.getFileIdsToSubmit();
      const requestPayload = {
        title: this.formObject.title,
        image: this.formObject.image,
        publishedAt: moment.utc(this.formObject.publishedAt).startOf('day').toDate(),
        abstract: this.formObject.abstract,
        text: this.formObject.text,
        status: status === null ? this.formObject.status : status,
        pdfFileIds,
      };
      try {
        await newsApi[this.editMode](requestPayload, this.$route.params.newsId);
        this.$notify({
          showClose: true,
          type: 'success',
          message: this.$t(`news.${this.editMode}.success`),
        });
        this.$router.go(-1);
      } catch (error) {
        this.$notify({
          showClose: true,
          type: 'error',
          message: error.response.data.error.message,
        });
      } finally {
        this.loading = false;
      }
    },
    async getFileIdsToSubmit() {
      if (!this.isEdit) {
        const uploads = await Promise.all(this.attachmentFilesList
          .map(file => this.uploadFile(file)));
        return uploads.map(response => response.data.id);
      }
      const filesToUpload = this.attachmentFilesList.filter(item => !item.fileId);
      const uploads = await Promise.all(filesToUpload
        .map(file => this.uploadFile(file)));
      return [
        ...this.attachmentFilesList.filter(item => item.fileId).map(item => item.fileId),
        ...uploads.map(response => response.data.id),
      ];
    },
    handleAttachmentUpload(file, fileList) {
      const max = 5;
      const isLt = fileSize => maxSize => fileSize / 1024 / 1024 < maxSize;
      const isLtMax = isLt(file.size)(max);
      if (!isLtMax) {
        this.$notify.error(this.$t('global.fileExceeded', [max]));
        this.attachmentFilesList = [...this.attachmentFilesList];
      } else {
        this.attachmentFilesList = fileList;
      }
    },
    async handleImageUpload(file) {
      this.formObject.image = await fileService.toBase64(file.raw);
    },
    async getAttachmentUrl(fileId) {
      const response = await uploadsApi.retrieveFile(fileId);
      return URL.createObjectURL(response.data);
    },
    downloadPdf(file) {
      if (file.raw) fileSaver.saveAs(file.raw);
      else if (file.url) fileSaver.saveAs(file.url, `${file.name}.pdf`);
    },
    async uploadFile(file) {
      const stream = await file.raw.arrayBuffer();
      return uploadsApi.uploadFile(stream);
    },
  },
};
</script>

<style lang="scss" >

.news-create-view {

  .form__image-box {
    margin: 1.5rem 0;
    width: 100%;
    position: relative;
    padding-top: calc(1 / 3 * 100%);

    .form__image {
      position: absolute;
      top: 0px;
      bottom: 0px;
      left: 0px;
      right: 0px;
      background-color: $--collapse-header-background-color;
      display: flex;
      justify-content: center;
      align-items: center;
      pointer-events: none;
    }
  }

  .form__switch {
    margin-top: -40px;
  }
}

</style>
